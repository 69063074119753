// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
// console.log('application pack')
// require("@rails/ujs").start()
// import "@hotwired/turbo-rails"
// require("@rails/activestorage").start()
// require("channels")

// import flatpickr from "flatpickr"
// require("flatpickr/dist/flatpickr.css")

// document.addEventListener("turbolinks:load", () => {
//  flatpickr("[data-behavior='flatpickr']", {
//    altInput: true,
//    altFormat: "F j, Y",
//    dateFormat: "Y-m-d",
//  })
//})

// document.addEventListener("direct-upload:initialize", event => {
//   const { target, detail } = event
//   const { id, file } = detail
//   console.log(target, id, file)
// })


// const Choices = require('choices.js')
// document.addEventListener("turbolinks:load", function() {
//     var dropDownSelects = new Choices('.form-select')
// })

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

//import "controllers"

// import { DirectUpload } from "@rails/activestorage"
// const input = document.querySelector('input[type=file]')

// input.addEventListener('change', (event) => {
//   Array.from(input.files).forEach(file => uploadFile(file))
//   // you might clear the selected files from the input
//   input.value = null
// })

// const uploadFile = (file) => {
//   const url = input.dataset.directUploadUrl
//   const upload = new DirectUpload(file, url)
//   upload.create( (error, blob) => {
//     const hiddenField = document.createElement('input')
//     hiddenField.setAttribute("type", "hidden")
//     hiddenField.setAttribute("value", blob.signed_id)
//     hiddenField.name = input.name
//     document.querySelector('form').appendChild(hiddenField)
//   })
// }

require("trix")
require("@rails/actiontext")